<template>
	<div class="e">
		<div class="e-item" v-for="(item,index) in data.filter(v => v.isShow)" :key="index">
			<div class="e-title">
				<i class="iconfont icon-youjiantou"></i>
				{{item.specName}}
			</div>
			<template v-if="item.specValueResultList && item.specValueResultList.length">
				<div class="e-sub" v-for="(sub,idx) in item.specValueResultList" :key="index + '-' + idx">
					<div class="e-sub-title">
						<span>{{sub.specValue}}</span>
						<el-button style="margin-right: 10px;" @click="changeSpec(item,sub)" type="text">选择规格</el-button>
					</div>
					<template v-if="sub.specList && sub.specList.length">
						<div class="e-content">
							<div class="e-row" v-for="(son,k) in sub.specList" :key="index + '-' + idx + '-' + k">
								<div class="e-row-title">{{son.specName}}</div>
								<div class="e-row-data">
									<span :class="['e-col',son.selectId === col.id ? 'e-col-active' : '']" @click="activeCol(sub,son,col.id)" v-for="(col,j) in son.specValueResultList.filter(v => v.isShow)" :key="index + '-' + idx + '-' + k + '-' + j">
										{{col.specValue}}
									</span>
								</div>
							</div>
						</div>
					</template>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:['data'],
		methods:{
			changeSpec(row,son){ //选择规格的点击事件
				this.$emit("changeSpec",row,son);
			},
			activeCol(sub,son,id){ //选择子规格的点击事件
				son.selectId = id;
				this.$emit("change",sub,son)
			}
		},
		created(){
			
		}
	}
</script>

<style scoped lang="less">
	.e-item{
		*{
			font-size: 14px;
		}
		.e-title{
			padding-bottom: 10px;
			line-height: 32px;
		}
		.e-sub{
			padding-left: 20px;
			.e-sub-title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				span{
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					box-sizing: border-box;
					padding-right: 10px;
				}
			}
			.e-content{
				width: 80%;
				margin-top: 10px;
				background: #F5F7FC;
				padding: 10px;
				.e-row{
					padding: 10px 20px;
					background: #fff;
					.e-row-title{
						border-bottom: 2px solid #F0F0F0;
						padding: 5px 0;
						margin-bottom: 5px;
					}
					.e-row-data{
						.e-col{
							line-height: 32px;
							padding: 0 30px 10px 0;
							display: inline-block;
							white-space: normal;
							overflow: hidden;
							text-overflow: ellipsis;
							cursor: pointer;
							font-size: 12px;
						}
						.e-col-active{
							color: @dh-color;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
</style>
