<template>
    <el-dialog
            width="1300px"
            top="6vh"
            :destroy-on-close="true"
            @open="open"
    >
        <div class="a">
            <div class="a-title">{{ title }}</div>
            <lida-table
                    :pagination="true"
                    :cutHeight="200"
                    :data="list"
                    :page="pageNo"
                    :pageSize="pageSize"
                    :total="total"
                    @change="pageChange"
                    border
                    @selection-change="selectGoods"
                    ref="table">
                <el-table-column @click="seleceIndex" :selectable="checkSelectable" type="selection" width="55" fixed/>
                <lida-table-column label="产品信息" fixed>
                    <template #default="scope">
                        <div class="g-info">
                            <div class="g-icon">
                                <img :src="scope.row.src"/>
                            </div>
                            <div class="g-right">
                                <div class="g-r-title">
                                    {{ scope.row.productName }}
                                    <div class="label" :title="scope.row.brandName">{{ scope.row.brandName }}</div>
                                </div>
                                <div class="g-r-data">
                                    {{ scope.row.barCode || '无' }}
                                    <span>|</span>
                                    {{ scope.row.seriesName || '无' }}
                                    <span>|</span>
                                    {{ scope.row.skuNumber || '无' }}
                                </div>
                            </div>
                        </div>
                    </template>
                </lida-table-column>
                <lida-table-column label="订货号" prop="articleNo"/>
                <lida-table-column label="面价" prop="guidePrice"/>
                <lida-table-column label="数量" prop="skuQty" v-if="showNumber">
                    <template #default="scope">
                        <el-input-number v-model="scope.row[numKey]" :min="0"/>
                    </template>
                </lida-table-column>
            </lida-table>
            <div class="a-footer">
                <el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
                <el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirm">确认</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {mapActions} from "vuex";
    import {ElMessage} from 'element-plus'
    import api from '@/common/js/api.js';
    import request from '@/common/js/request.js';

    export default {
        data() {
            return {
                list: [],
                total: 0,
                pageNo: 1,
                pageSize: 10,
            }
        },
        props: {
            ids: { //需要置灰的商品ids
                type: Array,
                default: () => {
                    return [];
                }
            },
            data: { //默认数据
                type: Array,
                default: () => {
                    return [];
                }
            },
            title: { //标题
                type: String,
                default: '添加商品'
            },
            val: { //筛选值
                type: String,
                default: '',
            },
            isQuery: { //是否进行搜索
                type: Boolean,
                default: false,
            },
            multiple: { //是否可多选
                type: Boolean,
                default: true,
            },
            showNumber: { //是否显示选择数量
                type: Boolean,
                default: true,
            },
            numKey: {
                type: String,
                default: 'skuQty',
            },
            skuId: {
                type: Number,
                default: null,
            }
        },
        methods: {
            ...mapActions('purchaseMudule', [
                'skuPage', 'getSku'
            ]),
            pageChange(page, pageSize) {
                //分页的改变事件
                this.pageNo = page;
                this.pageSize = pageSize;
                this.open()
            },
            open() {
                this.list = []
                //清空选中的项
                //this.$refs.table.$refs.lidaTable.clearSelection();
                if (this.isQuery) { //是否查询
                    if (this.skuId) {
                        request.get(api.getSku + "?skuId=" + this.skuId).then(({data}) => {

                            let item = data?.data
                            if (item) {
                                item[this.numKey] = 1
                                this.list.push(item);
                                this.total = 1
                                this.pageNo = 1;
                            } else {
                                this.total = 0
                                this.pageNo = 0;
                            }

                        });
                        return
                    }
                    if (this.val) {
                        request.post(api.skuPage, {
                            wd: this.val,
                            pageNo: this.pageNo,
                            pageSize: this.pageSize
                        }).then(res => {
                            let {code, data} = res.data;
                            if (code === '0') {
                                data.rows.map(item => {
                                    item[this.numKey] = 1;
                                })
                                this.list = data.rows;
                                this.total = data.totalCount
                                this.pageSize = data.pageSize;
                                this.pageNo = data.pageNo;
                            }

                        });
                        return
                    }
                    this.skuPage({
                        wd: this.val,
                        pageNo: this.pageNo,
                        pageSize: this.pageSize
                    }).then(res => {
                        let {code, data} = res.data;
                        if (code === '0') {
                            data.rows.map(item => {
                                item[this.numKey] = 1;
                            })
                            this.list = data.rows;
                            this.total = data.totalCount
                            this.pageSize = data.pageSize;
                            this.pageNo = data.pageNo;
                        }
                    })
                }
            },
            confirm() { //确定
                var data = JSON.parse(JSON.stringify(this.list.filter(v => v.active)));
                if (data.length) {
                    this.close();
                    this.$emit('add', data);
                } else {
                    ElMessage({
                        type: 'warning',
                        message: '请选中要添加的商品',
                    })
                }
            },
            selectGoods(data, row) {
                if (!this.multiple && data.length > 1) {
                    this.$refs.table.$refs.lidaTable.clearSelection();
                    this.$refs.table.$refs.lidaTable.toggleRowSelection(data.pop())
                    return false;
                }
                this.list.map(item => {
                    item.active = data.some(v => v.id === item.id);
                })
            },
            close() { //关闭弹框
                this.$emit('update:modelValue', false);
            },
            checkSelectable(row) {
                return !row.disabled;
            },
        },
        created() { //

        },
        watch: {
            data(v) {
                v.map(item => {
                    item.disabled = this.ids.some(v => v === item.id);
                })
                this.list = JSON.parse(JSON.stringify(v));
                //清空选中的项
                this.$nextTick(() => {
                    this.$refs.table.$refs.lidaTable.clearSelection();
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .a {
        padding: 20px;

        .a-title {
            padding-bottom: 20px;
            font-weight: bold;
        }

        .a-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
        }
    }
</style>
