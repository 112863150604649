<template>
	<div class="spec">
		<div class="spec-row" v-for="(item,index) in data" :key="item.id">
			<template v-if="item.isShow">
				<div class="spec-title">
					<i class="iconfont icon-youjiantou"></i>
					{{item.specName}}
				</div>
				<div class="spec-content">
					<div 
						:class="['spec-col',item.specValueId === son.id ? 'spec-col-active' : '']" 
						v-for="(son) in item.specValueResultList"
						@click="activeSpecCol(item,son,index)"
						:key="son.id">
							<template v-if="son.isShow">
								<span>{{son.specValue}}</span>
							</template>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:['data'],
		methods:{
			activeSpecCol(item,son,index){
				if(item.specValueId !== son.id){
					this.$emit('change',item,son,index);
				}
			}
		},
		watch:{
			data(v){
				//console.log(v);
			}
		}
	}
</script>

<style scoped lang="less">
	.spec{
		max-height: 100%;
		overflow-y: auto;
		*{
			font-size: 14px;
		}
		.spec-row{
			.spec-title{
				line-height: 32px;
				padding-bottom: 10px;
			}
			.spec-content{
				display: flex;
				flex-wrap: wrap;
				padding-left: 10px;
				.spec-col{
					max-width: 100%;
					color: #616161;
					cursor: pointer;
					span{
						padding: 0 20px 10px 0;
						box-sizing: border-box;
						display: inline-block;
					}
				}
				.spec-col-active{
					color: @dh-color;
				}
			}
		}
	}
</style>
